import Vue from "vue";
import App from "./app.vue";
import store from "./store";
import router from "./router";
import infraLayer from "@frontend/allspark/infra/index_finvin";
import UiKit from "@frontend/allspark/uikit/index_finvin";
import LayoutDefault from "./layouts/default";
import LayoutWidthFull from "./layouts/width-full";
import LayoutWidth932 from "./layouts/width-932";
import LayoutsFullScreen from "./layouts/full-screen";
import LayoutsBlank from "./layouts/blank";
import { combackerPopstate } from "@frontend/fin-recurrent-main/services/combacker";

(window.__VUE_DEVTOOLS_GLOBAL_HOOK__ || {}).Vue = Vue;

Vue.config.productionTip = false;
Vue.use(UiKit);
Vue.use(infraLayer);

Vue.component("layout-default", LayoutDefault);
Vue.component("layout-width-full", LayoutWidthFull);
Vue.component("layout-width-932", LayoutWidth932);
Vue.component("layout-full-screen", LayoutsFullScreen);
Vue.component("layout-blank", LayoutsBlank);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");

combackerPopstate();